import React from "react";
// import { graphql, Link } from "gatsby";
import {
    Box,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    UnorderedList,
    ListItem,
    useDisclosure
} from "@chakra-ui/react";


const AboutUs = (props) => {
    const textColorWhite = props.fromFooter;
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Button
                textColor={textColorWhite ? "white" : "grey.500"}
                onClick={onOpen}
                variant="unstyled"
            >
                <u>About Us</u>
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>About Us - VitalizeFresh</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>Established in the UAE since 2007, <b>VitalizeFresh</b> supplies:</Text>
                    <Box ml={4}>
                        <UnorderedList>
                            <ListItem>Fresh, Pre-Sorted & Selected Vegetables & Fruits</ListItem>
                            <ListItem>Freshly Cut, Sanitized Vegetables & Fruits</ListItem>
                            <ListItem>Freshly Squeezed Chilled Juices</ListItem>
                            <ListItem>Ready To Eat Fresh Sandwiches, Salads, Wraps & Ready-Made Meals</ListItem>
                            <ListItem>Freshly Baked Croissants, Muffins, Donuts, Cookies, Pizzas & Puffs</ListItem>
                            <ListItem>Celebration Cakes and Pre-Cut Pastry Slices</ListItem>
                            <ListItem>Fresh Dips, Dressings, Cooking Sauces & Purees</ListItem>
                        </UnorderedList>
                    </Box>
                    <Text mt={4} align="center"><u>Delivered 365 Days at Competitive Prices</u></Text>
                </ModalBody>
    
                <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                </Button>
                {/* <Button variant="ghost">Secondary Action</Button> */}
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default AboutUs;