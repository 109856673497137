
export const vitalizeConstants = {
    // Z-indices
    Z_NAVBAR: 10,
    Z_CARTICON: 11,

    LARGE_TEXT_SIZE: 8,
    
    // Cart
    VAT_AMOUNT: 5,
    DELIVERY_FEE: 7,
    DELIVERY_THRES: 50,

    SUPPORTED_EMIRATES: [
        "Abu Dhabi",
        "Dubai",
        "Sharjah",
        "Ajman"
    ],

    RECIPIENT_BCC: [
        'admin@vitalizefresh.com',
        'vitalizeinfo@gmail.com'
    ],
    
    EMIRATES_AR_ENG: {
        "أبو ظبي" : "Abu Dhabi",
        "إمارة دبيّ" : "Dubai",
        "إمارة الشارقةّ" : "Sharjah",
        "عجمان" : "Ajman"
    },

    // PGServer Data
    ARRAY_LEN_PROD_DESC: 3,
};