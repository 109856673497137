import React from "react";
// import { graphql, Link } from "gatsby";
import {
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";


const TermsConditions = (props) => {
    const textColorWhite = props.fromFooter;
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Button
                mb={textColorWhite ? 0 : 1}
                textColor={textColorWhite ? "white" : "grey.500"}
                onClick={onOpen}
                variant="unstyled"
            >
                <u>Terms and Conditions</u>
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Terms and Conditions</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                    <b><u>Disclaimer:</u></b> <br />
                    The pictures shown on the vitalizefresh.com website are for illustrative purposes only and may not always represent the exact appearance of the items our customers receive due to differences in origin, seasonal availability, import barriers, force majeure and other restrictions. 
                    </Text>
                    <Text mt={4}>
                    However, we reassure all our customers that our team will strive to get you the best quality of produce available. Please note that any items purchased through our e-commerce website cannot be returned for the sole reason that the items delivered do not match the appearance reflected on our website. If you find your delivery unacceptable, contact our supervisor and it may be replaced within the same or next day if we deem it necessary.
                    </Text>
                </ModalBody>
    
                <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                </Button>
                {/* <Button variant="ghost">Secondary Action</Button> */}
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default TermsConditions;