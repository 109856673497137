import React from "react";
// import { graphql, Link } from "gatsby";
import {
    Box,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    UnorderedList,
    ListItem,
    useDisclosure
} from "@chakra-ui/react";

import { vitalizeConstants } from "../utils/constants";

const OrderPolicy = (props) => {
    var supportedEmList = "";
    vitalizeConstants.SUPPORTED_EMIRATES.forEach((emi, i) => {
        supportedEmList += emi;
        if (i !== vitalizeConstants.SUPPORTED_EMIRATES.length - 1) supportedEmList += ", ";
    });

    const textColorWhite = props.fromFooter;
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Button
                mb={textColorWhite ? 0 : 1}
                textColor={textColorWhite ? "white" : "grey.500"}
                onClick={onOpen}
                variant="unstyled"
            >
                <u>Order Policy</u>
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Order Policy</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box ml={4}>
                        <UnorderedList>
                            <ListItem>
                                <Text>
                                    Currently we deliver to the following Emirates: <br></br>
                                    {supportedEmList}
                                </Text>
                            </ListItem>
                            <ListItem>Delivery is always the next morning</ListItem>
                            <ListItem>A person must be available to receive the delivery</ListItem>
                            <ListItem><b>The minimum order for free delivery is strictly AED 50</b></ListItem>
                            <ListItem>Orders below AED {vitalizeConstants.DELIVERY_THRES} will attract a AED {vitalizeConstants.DELIVERY_FEE} delivery charge</ListItem>
                        </UnorderedList>
                    </Box>
                    <Text mt={4}>
                    <b><u>Checking out with Cash/Card on Delivery:</u></b> <br />
                    </Text>
                    <Box ml={4}>
                        <Text>To visit Profile and other details, please click the user icon and select from the dropdown.</Text>
                        <Text>Customer must be logged in, along with the following details filled out in Profile:</Text>
                        <UnorderedList>
                            <ListItem>Email (must be verified)</ListItem>
                            <ListItem>Phone number (starting with +971, must be verified) </ListItem>
                            <ListItem>Address Line 1 (accurate delivery location) & Emirate</ListItem>
                        </UnorderedList>
                    </Box>
                    <Text mt={4}>
                    <b><u>Order Cutoff Time:</u></b> <br />
                    </Text>
                    <Box ml={4}>
                        <UnorderedList>
                            <ListItem>Orders placed by 12 noon will be delivered before 12 noon the next day</ListItem>
                            <ListItem>Orders placed after 12 noon will be delivered the day after</ListItem>
                        </UnorderedList>
                    </Box>
                </ModalBody>
    
                <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                </Button>
                {/* <Button variant="ghost">Secondary Action</Button> */}
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default OrderPolicy;