import React from "react";
import { Link } from "gatsby";
import { Box, Img, Text } from "@chakra-ui/react";
import logo from "../media/vitalize_logo_cropped.png";


const Logo = (props) => {
    return (
        <Link to={"/"}>
            <Box>
                <Img
                    w={{base: "18em", md: "10em"}}
                    src={logo} alt="Vitalize"
                    _hover={{ transform: "scale(1.05)" }}
                    _clicked={{ transform: "scale(1.05)" }}
                />
            </Box>
            <Box>
                <Text 
                    align="left"
                    padding={0}
                    fontSize={{base: "xs", sm: "sm", md: "md"}}
                    color={props.fromFooter ? "white" : "black"}
                >
                    Fresh, on time!
                </Text>
            </Box>
        </Link>
    );
};

export default Logo;