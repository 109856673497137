// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const FBConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
}
  
let firebaseInstance;
const getFirebase = (firebase) => {
    if (firebaseInstance) {
        return firebaseInstance;
    }

    firebase.initializeApp(FBConfig);
    firebaseInstance = firebase;

    return firebase;
}

export default getFirebase;