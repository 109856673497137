import React from "react";
import {
    Box,
    Text,
    Flex,
    Heading,
    Center,
    IconButton
} from "@chakra-ui/react";

import Logo from "./logo";
import AboutUs from "./aboutus";
import TermsConditions from "./tnc";
import OrderPolicy from "./policy";

import { AiOutlineWhatsApp, AiFillPhone, AiFillFacebook, AiFillInstagram, AiFillMail } from "react-icons/ai";

const Footer = () => {
    return (
        <Flex
            id="bot"
            align="baseline"
            direction="column"
            mt={6}
            px={3}
            py={6}
            bgColor="blue.900"
            w="100%"
        >
            <Box maxW="30%" mb={4}>
                <Logo fromFooter={true}/>
            </Box>

            <AboutUs fromFooter={true}/>
            <TermsConditions fromFooter={true}/>
            <OrderPolicy fromFooter={true}/>

            <Heading mt={3} size="sm" textColor="white">Contact Us</Heading>
            <Flex direction="row">
                <a href="https://api.whatsapp.com/send?phone=+971559023838/">
                    <Flex direction="row">
                        <IconButton icon={<AiOutlineWhatsApp size={35}/>} variant="unstyled" color="whiteAlpha.700"/>
                        <Text pt={2} color="whiteAlpha.700">055 9024141</Text>
                    </Flex>
                </a>
                <Heading size="lg" textColor="white">|</Heading>
                <a href="tel:+97142395747">
                    <Flex direction="row">
                        <IconButton icon={<AiFillPhone size={35}/>} variant="unstyled" color="whiteAlpha.700"/>
                        <Text pt={2} color="whiteAlpha.700">04 2395747</Text>
                    </Flex>
                </a>
            </Flex>
            <Text mt={1}color="whiteAlpha.700">customercare@vitalizefresh.com</Text>

            <Heading mt={3} size="sm" textColor="white">Address</Heading>
            <Text color="whiteAlpha.700">Office #212, Garhoud Star Bldg, Dubai, UAE</Text>
            <Text color="whiteAlpha.700">P.O. Box 37204</Text>

            <Center mt={3} w="100%">
                <a href="https://www.facebook.com/VitalizeFresh/">
                    <IconButton icon={<AiFillFacebook size={35}/>} variant="unstyled" color="whiteAlpha.700"/>
                </a>
                <a href="https://www.instagram.com/VitalizeFresh/">
                    <IconButton ml={2} icon={<AiFillInstagram size={35}/>} variant="unstyled" color="whiteAlpha.700"/>
                </a>
                <a href="mailto:customercare@vitalizefresh.com">
                    <IconButton icon={<AiFillMail size={35}/>} variant="unstyled" color="whiteAlpha.700"/>
                </a>
            </Center>
            <Text mt={3} size="sm" color="whiteAlpha.700"><span role="img" aria-label={"Copyright emoji"}>©️</span> {new Date().getFullYear()} VitalizeFresh. All rights reserved.</Text>
        </Flex>
    )
}

export default Footer;
