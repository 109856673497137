import { vitalizeConstants } from "./constants";

export const clone = (obj) => {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export const cloneJsonObj = (JsonObj) => {
    return JSON.parse(JSON.stringify(JsonObj));
}

export const getDiscountedPrice = (price, percent) => {
    return price - (price * percent / 100);
}

export const getTax = (price) => {
    return (price * ((100 + vitalizeConstants.VAT_AMOUNT)/100)) - price;
}

export const getTaxedPrice = (price) => {
    return price + getTax(price);
}

export const hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);

export const formatPrice = p => {
    let reg = /[.]/g;
    if (p === 0) return "0 AED";
    p += "";
    if (p.search(reg) !== -1) {
        if (p.split(".")[1].length === 1) p += "0";
    }
    else p += ".00";
    return p + " AED";
}

export const removeWS = (dirtyString) => dirtyString.split("/")[0].replace(/\W/g, "");

// Check if window is defined (so if in the browser or in node.js).
export const isBrowser = () => typeof window !== "undefined";

// From: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const isMobile = () => {
    if (!isBrowser()) return false;
    var hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    }
    else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    }
    else {
        var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
        }
        else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        }
        else {
            // Only as a last resort, fall back to user agent sniffing
            var UA = navigator.userAgent;
            hasTouchScreen = (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }
    return hasTouchScreen;
}

export const getAnalyticsProduct = (id, name, category, price, quantity) => {
    return {
        item_id: id,
        item_name: name,
        item_category: category,
        price: price,
        quantity: quantity
    }
}