import * as utils from "./utility_funcs";


const UserMgr = () => {

    // Instance stores a reference to the UserMgr Singleton
    var instance;

    function init() {
        // Private variables and methods
        var privateCart = {};
        var privateProductList = {};
        var privateCategoryList = [];
        var currentUser = null;
        var analytics = null;
        var logModule = null;
        var sid = null;
        
        return {
            // Public methods
            Init : (prods, cats) => {
                var PC = null;
                var PPL = null;
                var PCL = null;

                if (utils.isBrowser()) {
                    PC = localStorage.getItem("PC");
                    PPL = localStorage.getItem("PPL");
                    PCL = localStorage.getItem("PCL");
                }
                var privateCartLS;
                if (PC) {
                    privateCartLS = JSON.parse(PC);
                    if (!prods || !cats) {
                        privateCart = JSON.parse(PC);
                    }
                }
                if (PPL) privateProductList = JSON.parse(PPL);
                if (PCL) privateCategoryList = JSON.parse(PCL);
                //const ekek = Object.entries(privateCartLS);
                            
                if (prods && cats) {
                    // TODO: fix warning:  Function declared in a loop contains unsafe references to variable(s) 'privateCart'  no-loop-func
                    // Initialize cart (if we local store use it)
                    for (const prod of Object.entries(prods)) {
                        const p = prod[1];
                        const units = (!p.packunitSizes.weights) ? p.packunitSizes.juiceSizes : p.packunitSizes.weights;
                        units.forEach((unit, i) => 
                            privateCart[p.contentful_id + "_" + utils.hashCode(unit)] = {
                                name: p.name,
                                quantity: privateCartLS ?
                                (privateCartLS[p.contentful_id + "_" + utils.hashCode(unit)] ? privateCartLS[p.contentful_id + "_" + utils.hashCode(unit)].quantity : 0) : 0,
                                moq: p.moq ? p.moq : 1,
                                unit: unit,
                                price: p.prices[i],
                                discount: p.discount,
                                category: p.categoryAndSubCategory.categoryName,
                                thumb: p.imageThumb.file.url
                            }
                        );
                    }
                    privateProductList = utils.clone(prods);
                    privateCategoryList = utils.clone(cats);

                    if (utils.isBrowser()) {
                        localStorage.removeItem("PPL");
                        localStorage.removeItem("PCL");
                        localStorage.setItem("PPL", JSON.stringify(privateProductList));
                        localStorage.setItem("PCL", JSON.stringify(privateCategoryList));
                    }
                }
            },

            AuthListener : (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    currentUser = user;
                    localStorage.removeItem("AU");
                    localStorage.setItem("AU", JSON.stringify(user));
                    // ...
                    console.log("SIGNED IN: ", user.displayName);
                } else {
                    // User is signed out
                    // ...
                    currentUser = null;
                    localStorage.removeItem("AU");
                    console.log("SIGNED OUT");
                }
            },

            GetCurrentUser : () => {
                if (utils.isBrowser() && !currentUser) {
                    const AU = localStorage.getItem("AU");
                    if (AU) {
                        currentUser = JSON.parse(AU);
                    }
                    else return null;
                }
                return currentUser;
            },

            GetProductList : () => {
                var publicProductList = utils.clone(privateProductList);
                if (!publicProductList) return [];
                // Prune unwanted data
                for(const entry of Object.entries(publicProductList)) {
                    // console.log(entry)
                    delete entry[1].categoryAndSubCategory.contentful_id;               
                    delete entry[1].image;
                    delete entry[1].imageLowRes;
                    delete entry[1].discount;
                    delete entry[1].moq;
                    delete entry[1].packunitSizes;
                    delete entry[1].prices;
                }
                return publicProductList;
            },

            GetCategoryList : () => privateCategoryList,
            
            GetCart : () => {
                var publicCart = [];
                for(const entry of Object.entries(privateCart)) {
                    const p = entry[1];
                    if(p.quantity === 0) continue;
                    else {
                        publicCart.push([p.name, p.unit, p.quantity, utils.getDiscountedPrice(parseFloat(p.price), p.discount), entry[0], p.category, p.thumb, p.moq]);
                    }
                }
                return publicCart.sort();
            },

            GetCartSize : () => {
                var count = 0;
                for(const entry of Object.entries(privateCart)) {
                    const p = entry[1];
                    if(p.quantity === 0) continue;
                    else count += p.quantity;
                }
                return count;
            },

            CartAdd : (prod) => {
                if (!prod.quantity) return false;
                const cartIdx = prod.fromHistory ? // If this product is being added from order history, dont hash packUnit
                                prod.id
                                : prod.id + "_" + utils.hashCode(prod.validUnit[prod.select]);
                if (!privateCart[cartIdx]) {
                    console.log("FAILED ADD CART" + (prod.fromHistory ? " FROM HISTORY" : ""));
                    return false;
                }
                privateCart[cartIdx].quantity += prod.quantity;

                // Refresh local store
                localStorage.removeItem("PC");
                localStorage.setItem("PC", JSON.stringify(privateCart));

                // Log adding product to cart
                const p = privateCart[cartIdx];
                if (logModule) {
                    logModule.logEvent(analytics, "add_to_cart", {
                        currency: 'USD',
                        value: p.price,
                        items: utils.getAnalyticsProduct(cartIdx, p.name, p.category, p.price, p.quantity)
                    });
                }
                return true;
            },
            
            CartRemove : (prod, hash) => {
                const cartIdx = (hash) ? hash : prod.id + "_" + utils.hashCode(prod.validUnit[prod.select]);
                if (!privateCart[cartIdx]) {console.log("FAILED REMOVE CART"); return; }

                privateCart[cartIdx].quantity = 0;

                // Refresh local store
                localStorage.removeItem("PC");
                localStorage.setItem("PC", JSON.stringify(privateCart));
                
                // Log removing product from cart
                const p = privateCart[cartIdx];
                if (logModule) {
                    logModule.logEvent(analytics, "remove_from_cart", {
                        currency: 'USD',
                        value: p.price,
                        items: utils.getAnalyticsProduct(cartIdx, p.name, p.category, p.price, p.quantity)
                    });
                }
            },

            CartClear : () => {
                localStorage.removeItem("PC");
                for(const entry of Object.entries(privateCart)) {
                    const p = entry[1];
                    p.quantity = 0;
                }
                localStorage.setItem("PC", JSON.stringify(privateCart));
            },
            
            CartItemExists : (prod) => {
                const cartIdx = prod.id + "_" + utils.hashCode(prod.validUnit[prod.select]);
                if (!privateCart[cartIdx]) return false;
                return (privateCart[cartIdx].quantity);
            },

            SetAnalytics : (fbObj, logMod) => {
                //console.log(fbObj, logMod);
                if (!analytics || !logModule) {
                    analytics = fbObj;
                    logModule = logMod;
                }
            },

            AnaLog : (s, param) => {
                if (logModule) {
                    console.log("LOGGING EVENT: ", s);
                    console.log("PARAM:", param);
                    logModule.logEvent(analytics, s, param);
                }
            },

            SetTransaction : (transaction) => {
                localStorage.removeItem("TR");
                if (!transaction) return;
                localStorage.setItem("TR", JSON.stringify(transaction));
            },

            GetTransaction : () => {
                const TR = localStorage.getItem("TR");
                if (!TR) console.log("NULL TRANSACTION IN GET");
                if (TR) return JSON.parse(TR);
                return null;
            },

            SetVerifySID : (verifySID) => sid = verifySID,

            GetVerifySID : () => sid,

        };
    };

    return {
        // Get the UserMgr Singleton instance if one exists
        // or create one if it doesn't
        getInstance : () => {
            if (!instance) {
                instance = init();
                instance.Init();
            }
            return instance;
        }
    };
};

export default UserMgr();